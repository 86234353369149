import React from 'react';
import { Parallax } from 'react-parallax';

const ParallaxTitle = () => {
  return (
    <Parallax
      bgImage="/fond.jpeg"
      strength={500}
      className="h-screen flex items-center justify-center bg-cover"
    >
      <h1 className="text-white text-5xl md:text-7xl lg:text-8xl font-bold text-center overflow-hidden">
        Joyeux anniversaire le reuf 👍
      </h1>
      <p className="text-gray-200 text-2xl md:text-4xl lg:text-5xl font-bold text-center py-12">Scroll en bas ⬇️</p>
    </Parallax>
  );
};

export default ParallaxTitle;