import React from "react";

const WelcomeScreen = ({ onSelection }) => {
  return (
    <div className="flex flex-col items-center justify-center h-screen space-y-6">
      <h1 className="text-white text-4xl">Qui êtes-vous ?</h1>
      <p className="text-gray-300 text-2xl text-center">(Veuillez activer le son, c'est important)</p>
      <button
        className="bg-white text-black font-medium py-4 w-52 text-xl  hover:border-white hover:border hover:bg-transparent hover:text-white transition-all ease-in duration-200"
        onClick={() => onSelection("Ayoub")}
      >
        Ayoub 🎉
      </button>
      <button
        className="bg-white text-black font-medium py-4 w-52 text-xl hover:border-white hover:border hover:bg-transparent hover:text-white transition-all ease-in duration-200"
        onClick={() => onSelection("Autres")}
      >
        Autres
      </button>
    </div>
  );
};

export default WelcomeScreen;
