import React, { useState, useEffect, useRef } from "react";
import ParallaxTitle from "./ParallaxTitle";
import { motion } from "framer-motion";
import WelcomeScreen from "./WelcomeScreen";
import Confetti from "react-confetti";
import useWindowSize from "./useWindowSize"; // Import du hook personnalisé
import ReactPlayer from "react-player";

const App = () => {
  const [stage, setStage] = useState("welcome");
  const { width, height } = useWindowSize();
  const audioRef = useRef(new Audio("/audio/birthday.mp3")); // Référence à l'objet Audio

  useEffect(() => {
    return () => {
      // Nettoyage : arrêter l'audio et remettre à zéro
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    };
  }, []);

  const handleSelection = (choice) => {
    if (choice === "Ayoub") {
      setStage("birthday");
    } else {
      setStage("rejected");
    }
  };

  const containers = [
    {
      title: "C'est mal parti déjà... (pour le cadeau)",
      text: "Alors tout d'abord, faut savoir que t'as des goûts assez luxueux à la base, ce que je ne peux pas me permettre étant donné que je n'ai plus tant de commandes free-lance et donc moins d'argent. Ensuite, t'es un peu mystérieux donc je sais pas trop ce qui te fait plaisir à la base, mais c'est pas grave, l'important c'est de participer en te proposant ce site..",
      img: "/mal_parti.jpg",
    },
    {
      title: "Ce site comme idée de cadeau",
      text: "Entre deux intégrales et quelques dérivations de fonction composées, j'me suis dit, cet été, que j'allais te faire un site pour te souhaiter un joyeux anniversaire, le site a été fait à la va vite (2-3h de code environ) mais c'est normal, c'est parce que je te prépare une petite surprise à la fin. Toi qui aime bien le foot...",
      img: "https://pbs.twimg.com/media/GSZ_rM1XMAACXlY?format=jpg&name=small",
    },
    {
      title: "Quelques souvenirs...",
      text: "Avant tout le raffut qui va arriver, je tenais à te montrer quelques petits souvenirs qu'on a en commun car pourquoi pas, quitte à être gênant, autant l'être jusqu'au bout.",
      img: "/yafoy.png",
    },
    {
      title: "En fait non Sasuke",
      text: "Ah frère, t'es trop mystérieux, on a 0 photos en commun quasiment, c'est fort, même dans la caverne d'Ali-baba qu'est la galerie photos de Maman (+40000 photos) y'a aucune photos de nous deux... Mais c'est pas grave, j'tapprécie quand même 👍",
      img: "https://i.pinimg.com/474x/6c/ce/86/6cce86b5e63e3c02dd78162946d15939.jpg",
    },
    {
      title: "Une petite lettre de Maman",
      text: `"ايوب حبيبي ♥️
عيد ميلاد سعيد وعمر مديد 
اطال الله في عمرك في طاعته ورزقك الصحة والعافية والمعافات 
اسأل الله القدير ان يبارك لك في عمرك وينعم عليك بالخير ما دمت حيا وبالبركات ما يكفيك ويرضيك 
Boulboul hbibi ♥️
Joyeux anniversaire !
Je te souhaite en ce jour spécial une bonne santé plein de bonheur et réussite fi taati llah  
ورزقك الله الذرية الصالحة مع ياسمين 🤲
Hbibi 3id milad sa3id rabi ya3tik assaha wa ssalama ou yaj3al wajhak 9amar ou foumak tmmar🤲"
      
      - Un être humain au grand coeur`,
      img: "/billy_coquette.png",
    },
    {
      title: "Une petite lettre de Papa",
      text: `"عزيزي أيوب 
شمعة أخرى لعمر ك نسأل الله لك فيها السعادة والهناء والذرية الطيبة  
إعمل لدنياك كأنك تعيش أبدا 
واعمل لآخرتك كأنك  تموت غدا 
الطريق السوي ظاهر للعيان هو دينك وليكن النبراس الذي ينير لك الطريق إلى طاعة الله أنت وأهلك وأولادك ان شاء الله 
عام سعيد وعمر مديد في طاعة المولى عز و جل 
والسلام"
      
      - بابا نوال`,
      img: "/billy_alien.png",
    },
    {
      title: "Une petite lettre de tes frères",
      text: `"Cher Ayoub,

Joyeux anniversaire, grand frère! 🎉

32 ans déjà et la fleur de l’âge! Que cette nouvelle année d’anniversaire te réserve plein de surprises, de rires et de moments inoubliables inchAllah. Profite à fond de ta journée et n’oublie pas de garder une part de gâteau pour moi 

Merci d’être là pour nous 🙌🏼✨

Passe une journée exceptionnelle !

Avec tout mon amour fraternel

Wa haleykoum assalam"
      
      - Imad "L'entrepreneur"
      
      "Salam Bsahtek pour l’anniversaire passe une bonne journée car c’est que une fois par ans"

      - À toi de deviner`,
      img: "/billy_fermier.png",
    },
    {
      title: "Une petite lettre de ta soeur",
      text: `"Salem Ayoub,
Je te souhaite un très bon anniversaire profite de ta journée comme il se doit, fait toi plaisir, prend un petit peu de répit dans ta fastlife. Je te souhaite beaucoup de paix, d’amour et beaucoup de moneyyyy (et aussi la santé). 
Qu’allah t’accorde des yachts à Monaco et des porches édition limités ainsi que des lamborghinis. Amine. 
Le 8 aout c’est le jour qui me rappelle tous les ans  à quel point je suis très honoré d’être ta petite soeur. 
Je te fais des gros bisous !!!
Longue vie à toi, de la part de ta petite soeur d’amour."
      
      - Youssra "la modérateur Discord"`,
      img: "/billy_avatar.png",
    },
  ];

  return (
    <div className="bg-black min-h-screen">
      {stage === "welcome" && <WelcomeScreen onSelection={handleSelection} />}
      {stage === "birthday" && (
        <BirthdayScreen onProceed={() => setStage("home")} />
      )}
      {stage === "rejected" && (
        <RejectedScreen onProceed={() => setStage("home")} />
      )}
      {stage === "home" && (
        <>
          <Confetti width={width} height={height} />
          <ParallaxTitle />
          <div className="container mx-auto px-4 py-8 space-y-12">
            {containers.map((container, index) => (
              <AnimatedContainer
                key={index}
                index={index}
                container={container}
              />
            ))}
          </div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="container mx-auto px-4 py-8"
          >
            <motion.h2
              className="text-white text-4xl font-bold text-center"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 1 }}
            >
              Et le meilleur pour la fin...
            </motion.h2>
            <motion.div
              className="text-white flex justify-center items-center flex-col text-start"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 2 }}
            >
              <ReactPlayer
                url="/menes.mp4"
                controls
                width="60%"
                height="auto"
              />
              <p className="mt-4">
                Voici un message spécial de Pierre Ménès pour te souhaiter un
                joyeux anniversaire !
              </p>
              <p className="mt-12">
                En espérant que ce site t'as fait sourire, si c'est pas le cas,
                j'men fou, moi j'ai rigolé en le codant donc c'est le plus
                important, en tout cas passe une bonne journée et profite !!!
                c'est ton anniversaire 😁
              </p>
            </motion.div>
          </motion.div>
        </>
      )}
    </div>
  );
};

const AnimatedContainer = ({ index, container }) => {
  const [inView, setInView] = useState(false);
  const ref = useRef() as any;

  const handleScroll = () => {
    const top = ref.current.getBoundingClientRect().top;
    const height = window.innerHeight;
    if (top < height && top > 0) {
      setInView(true);
    } else {
      setInView(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ duration: 1, delay: index * 0.2 }}
      className="flex flex-col lg:flex-row items-center space-y-6 lg:space-y-0 lg:space-x-6"
    >
      <div className="lg:w-1/2">
        <motion.h2
          className="text-white text-3xl font-bold mb-4"
          initial={{ opacity: 0, y: 20 }}
          animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
          transition={{ duration: 0.5, delay: index * 0.2 }}
        >
          {container.title}
        </motion.h2>
        <motion.p
          className="text-white text-lg"
          initial={{ opacity: 0, y: 20 }}
          animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
          transition={{ duration: 0.5, delay: index * 0.2 + 0.1 }}
        >
          {container.text.split("\n").map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </motion.p>
      </div>
      <motion.div
        className="lg:w-1/2"
        initial={{ opacity: 0, y: 20 }}
        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
        transition={{ duration: 0.5, delay: index * 0.2 + 0.2 }}
      >
        <img
          src={container.img}
          alt={`Illustration pour ${container.title}`}
          className="w-full h-auto rounded-lg"
        />
      </motion.div>
    </motion.div>
  );
};

const BirthdayScreen = ({ onProceed }) => {
  const [showButton, setShowButton] = useState(false);
  const [buttonAttempts, setButtonAttempts] = useState(0);
  const [buttonTextIndex, setButtonTextIndex] = useState(0);
  const [buttonPosition, setButtonPosition] = useState({
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  });

  const buttonTexts = [
    "Clique moi dessus si tu peux",
    "Bien essayé, ici",
    "J'sais j'suis un petit blagueur",
    "Bientôt champion",
    "On lâche rien allez allez",
    "Vzy j'rigole, clique",
  ];

  // Dimensions et limites de la zone du bouton
  const buttonAreaSize = 200; // taille de la zone en pixels

  const audioRef = useRef(new Audio("/audio/birthday.mp3"));

  useEffect(() => {
    audioRef.current.play();

    const timeoutId = setTimeout(() => {
      setShowButton(true);
    }, 10000);

    return () => {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      clearTimeout(timeoutId);
    };
  }, []);

  const handleButtonMove = () => {
    const max = buttonAreaSize - 100; // 100 est la largeur du bouton, pour ne pas le couper

    const newLeft = Math.random() * max;
    const newTop = Math.random() * max;

    setButtonPosition({
      top: `${newTop}px`,
      left: `${newLeft}px`,
      transform: "translate(-50%, -50%)",
    });

    setButtonAttempts(buttonAttempts + 1);
    setButtonTextIndex(buttonTextIndex + 1);

    if (buttonAttempts >= buttonTexts.length - 1) {
      setButtonPosition({
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      });
      setShowButton(false);
      setTimeout(() => {
        onProceed();
      }, 1000);
    }
  };

  return (
    <div className="relative flex flex-col items-center justify-center h-screen text-center">
      <motion.h1
        className="text-white text-4xl font-bold"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        C'est ton anniversaire ! 🎉
      </motion.h1>
      {showButton && (
        <motion.button
          onClick={handleButtonMove}
          className="bg-white text-black p-4 rounded-xl"
          style={{
            position: "absolute",
            top: buttonPosition.top,
            left: buttonPosition.left,
            transform: buttonPosition.transform,
          }}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {buttonTexts[buttonTextIndex]}
        </motion.button>
      )}
    </div>
  );
};

const RejectedScreen = ({ onProceed }) => {
  const [showButton, setShowButton] = useState(false);
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    const audio = new Audio("/audio/rejection.mp3"); // Assurez-vous d'ajouter un fichier audio nommé 'rejection.mp3' dans le dossier public
    audio.play();
    audio.onended = () => {
      setShowText(true);
      setTimeout(() => {
        setShowButton(true);
      }, 3000);
    };
  }, []);

  const handleProceed = () => {
    onProceed();
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen space-y-6 text-center">
      <motion.h1
        className="text-white text-5xl font-bold"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Salem, t'es pas sélectionné
      </motion.h1>
      <motion.img
        src="/singe.png"
        className="w-96 h-96 rounded-sm"
        alt="Un singe triste"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      />
      {showText && (
        <motion.text
          className="text-white text-2xl"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          J'rigooooole le sang, tiens j'te fait croquer ⬇
        </motion.text>
      )}
      {showButton && (
        <motion.button
          onClick={handleProceed}
          className="bg-white text-black font-medium py-4 w-52 text-xl  hover:border-white hover:border hover:bg-transparent hover:text-white transition-all ease-in duration-200"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Clique ici frangin
        </motion.button>
      )}
    </div>
  );
};

export default App;
